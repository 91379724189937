.gridRow {
  /*fill: #fff;*/
  fill: transparent;
  
}

.gridRow:nth-child(even) {
  fill: #f5f5f5;
}
.gridRow:hover{
  /*fill: aqua;*/
}


/*.gridRowLine {
  stroke: #D3D3D3;
  stroke-width: .5px;
}
.gridRowLineFirst {
  stroke: #D3D3D3;
  stroke-width: 1px;
}
.gridRowLineLast {
  stroke: #D3D3D3;
  stroke-width: 1px;
}*/


.gridRowDark {
  /*fill: #fff;*/
  fill: transparent;
  
}

.gridRowDark:nth-child(even) {
  fill: #3b3a39;
}
.gridRowDark:hover{
  /*fill: aqua;*/
}