.datetooltipparent {
    width: 150px;
    padding: 5px;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 2px, rgb(0 0 0 / 14%) 0px 8px 16px;
    display: grid;
    grid-template-columns: repeat(2, 3fr) .5fr repeat(2, 3fr);
    grid-template-rows: .2fr repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-align: center;
    font-size:small;
    }
    
.datetooltipdiv1 { grid-area: 1 / 1 / 2 / 2; font-size: x-small;}
.datetooltipdiv2 { grid-area: 1 / 2 / 2 / 3;  font-size: x-small;}
.datetooltipdiv3 { grid-area: 1 / 3 / 2 / 4; }
.datetooltipdiv4 { grid-area: 1 / 4 / 2 / 5;  font-size: x-small;}
.datetooltipdiv5 { grid-area: 1 / 5 / 2 / 6;  font-size: x-small;}
.datetooltipdiv6 { grid-area: 2 / 1 / 3 / 2; }
.datetooltipdiv7 { grid-area: 2 / 2 / 3 / 3; }
.datetooltipdiv8 { grid-area: 2 / 3 / 3 / 4; }
.datetooltipdiv9 { grid-area: 2 / 4 / 3 / 5; }
.datetooltipdiv10 { grid-area: 2 / 5 / 3 / 6; }
.pgpopup
{
    padding: 5px;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 2px, rgb(0 0 0 / 14%) 0px 8px 16px;
    display: flex;
    
}
.pgdate
{
    display: flex;
}
.pgdayname
{
    width: 40px;
}
.pgdaynumber
{
    width: 40px;
}
.pgmonthname
{
    width: 40px;
}
.pgyearnumber
{
    width: 40px;
}
.pgdatesep{
    margin-left: 5px;
    margin-right: 5px;
}
.loadprogressbar
{
    transition: all .2s ease-in-out;
    height:8px;
    background-color:rgb(91, 95, 199);;
    border-radius: 2px;
}
.loadprogressarea
{
    background-color: rgb(250, 249, 248);
    border-radius: 2px;
}
.ganttcontainer
{
    overflow-y: scroll;
    overflow-x: hidden; /*avoid horizontal scroll when mouse is over a long task with a tooltip outside the screen*/
    max-height: calc(100vh - 100px);/* - messes up visibility on iphone*/
}
.authBtn {
    
    display: flex;
    width: 87vw;
    height: 87vh;
   
}
.authContent{
    margin: auto;
    display: grid;
    text-align: center;
}
.authBtn button{
    margin: auto;
}

.mobiletaskitem{
    font-size: 17px;
}
