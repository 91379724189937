.view-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
}
select:focus {
    outline: none; /* Removes the border on focus */
    box-shadow: none; /* Removes any focus-related shadow (for browsers like Chrome) */
  }
.view-dropdown-select
{
    
   
    z-index: 999;
    
    outline: 'none';
    appearance: 'none';

    line-height: 3;
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
    
    cursor: pointer;
}
.lighttheme .view-dropdown-select
{
    background-color: rgb(243, 242, 241);
    color: rgb(72, 70, 68);
    border-color: rgb(243, 242, 241);
}
.darktheme .view-dropdown-select
{
    background-color: rgb(32, 31, 31);
    color: #fff;
    border-color: rgb(32, 31, 31);
}
.view-dropdown select{
    border-bottom: 2px solid transparent; /* Adds a blue line under the dropdown when options are visible */
}
.view-dropdown select:focus {
    border-bottom: 2px solid rgb(147, 153, 245); /* Adds a blue line under the dropdown when options are visible */
}
