.barWrapper {
  cursor: pointer;
  outline: none;
}

.barWrapper:hover .barHandle {
  visibility: visible;
  opacity: 0.5;
  transition: opacity .5s ease-in-out;   
}

.barHandle {
  fill: #FFF;
  cursor: ew-resize;
  opacity: 0;
  visibility: visible;
  transition: opacity .5s ease-in-out;   
}

.barBackground {
  user-select: none;
  -webkit-user-select: none;
  stroke-width: 0;
  filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .2));
}

.barWrapperAni {
  cursor: pointer;
  outline: none;
}

.barWrapperAni:hover .barHandle {
  visibility: visible;
  opacity: 0.5;
  transition: opacity .5s ease-in-out;   
}

.dependencyLink {
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s ease-in-out;   
  fill: gray;
}

.barWrapperAni:hover .dependencyLink {
  visibility: visible;
  opacity: 0.8;
  transition: opacity .5s ease-in-out;   
}
.barWrapper:hover .dependencyLink {
  visibility: visible;
  opacity: 0.8;
  transition: opacity .5s ease-in-out;   
}




.barWrapperAni rect
{
  transition-property: x, width; 
  transition-duration: .5s; 
  transition-timing-function: ease-out; 
}
g > g > text
{
  transition: opacity .2s ease-in-out;
  
}


