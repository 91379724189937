.calendarBottomText {
  text-anchor: middle;
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  
  /*animation: calendarBottomTextAnimation .6s ease-in-out;*/
}

.calendarTopTick {
  /*stroke: #e6e4e4;*/
}

.calendarTopText {
  text-anchor: middle;
  fill: #555;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.calendarTopTextDark {
  text-anchor: middle;
  fill: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.calendarHeader {
  
  transition: none;
  /*fill: transparent;fill: #ffffff;
  stroke: #e0e0e0;
  stroke-width: 1.4;*/
}
.calendarHeader text {
  
}
@keyframes calendarBottomTextAnimation {
	from {
		opacity: 0;
	}
	to {
		
		opacity: 1;	
	}
}
