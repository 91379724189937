.narrow {
  max-width: 3400px;
  margin: 0 auto;
}

.page-padding {
  padding-left: 4rem;
  padding-right: 4rem;
}
.mobile-page-padding {
  /*margin-left: calc(50vw - 12em);
  /*padding-right: 10vw;*/
  /*display: flex; Causes problems on IOS*/
  justify-content: center;
}
html {

}
.welcome.page
{
  overflow: hidden;
  animation: bodyfadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes bodyfadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.welcome.page > .narrow > img {
  margin: 0 auto;
  display: block;
  width: 200px;
  
}

.welcome.page > .narrow > ul {
  width: 80%;
  justify-content: space-between;
  margin: 4rem auto;
  border-bottom: none;
}

.welcome.page > .narrow > ul > li {
  background-color: inherit;
  margin: auto;
}

.welcome.page > .narrow > ul > li > a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.center {
  text-align: center;
}

.sections > * {
  margin: 4rem auto;
}

pre,
div.error {
  background-color: #e5e5e5;
  padding: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 1rem 0;
  max-height: 200px;
  overflow-x: scroll;
  overflow-y: scroll;
}

pre.fixed,
div.error.fixed {
  height: 200px;
}

code {
  background-color: #e5e5e5;
  display: inline-block;
  padding: 0px 6px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.error {
  color: red;
  margin: 0 0 1rem 0;
  font-size: medium;
}
.warning {
  color: #eaa300;
  margin: 0 0 1rem 0;
  font-size: medium;
}

/*AVATAR CARD*/
.planavatarclstxt > span {color: white;}
.planavatarcls1 > span {background-color: #23874B;}
.planavatarcls2 > span {background-color: #8151FD;}
.planavatarcls3 > span {background-color: #D50AC6;}
.planavatarcls4 > span {background-color: #EC0E3F;}
.planavatarcls5 > span {background-color: #2B5797;}
.planavatarcls6 > span {background-color: #99B433;}
.planavatarcls7 > span {background-color: #00ABA9;}
.planavatarcls8 > span {background-color: #FF0097;}
.planavatarcls9 > span {background-color: #B91D47;}
.planavatarcls10 > span {background-color: #00A300;}
.planavatarcls11 > span {background-color: #7E3878;}
.planavatarcls12 > span {background-color: #DA532C;}
.planavatarcls13 > span {background-color: #6BA5E7;}
.planavatarcls14 > span {background-color: #E773BD;}
.planavatarcls15 > span {background-color: #2D89EF;}
.planavatarcls16 > span {background-color: #4E5284;}
.planavatarcls17 > span {background-color: #FF9900;}
.planavatarcls18 > span {background-color: #1500FF;}
.planavatarcls19 > span {background-color: #6BA5E7;}
.planavatarcls20 > span {background-color: #23874B;}

.avatarcard  {user-select: none; -webkit-user-select:none; cursor: pointer;}
