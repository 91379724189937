.darktheme
{

    background-color: rgb(45, 44, 44);
    color: #fff;
    border-color: rgb(45, 44, 44);
}
.lighttheme
{
    background-color: rgb(238, 238, 238);
    color: #000;
    border-color: rgb(238, 238, 238);
}
.rootheatmap
{
    animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
