/* HeatmapComponent.css */

.heatmap-container {
    padding: 0;
    overflow: auto;
    position: relative;
  }
  
  .heatmap-grid {
    display: grid;
    position: relative;
    min-width: max-content; /* Ensure grid doesn't shrink */
  }
  
  .heatmap-empty-corner {
    position: sticky;
    top: 0;
    z-index: 3; /* Higher z-index to ensure it stays on top */
  }
  
  .heatmap-x-label {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: sticky;
    top: 20px;
    background-color: inherit;
    z-index: 3;
    font-size: x-small;
    user-select: none;
  }
  .darktheme .heatmap-x-label {
    background-color: rgb(45, 44, 44);
  }
 
  .lighttheme .heatmap-x-label {
    background-color: rgb(238, 238, 238);
  }
  
  
  .heatmap-y-label {
    display: flex;
    text-align: left;
    
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 5px;
    padding-left: 4px;
    position: sticky;
    left: 0;
   
    z-index: 2; /* Higher z-index to ensure it stays on top */
    font-size: smaller;
    opacity: .9;
    border-radius: 2px;
    user-select: none;
    
  }
  .heatmap-y-label-center {
    
    margin-top: auto;
    margin-bottom: auto;
  }
  .lighttheme .heatmap-y-label {
    background: linear-gradient(to right, white, transparent);
  }
  .darktheme .heatmap-y-label {
    background: linear-gradient(to right, #333, transparent);
  }
  .lighttheme .heatmap-cell {
    border-bottom: 1px solid rgb(238, 238, 238);
    border-left: 1px solid rgb(238, 238, 238);
  }
  .darktheme .heatmap-cell {
    border-bottom: 1px solid rgb(45, 44, 44);
    border-left: 1px solid rgb(45, 44, 44);
  }
  .heatmap-cell {
    width: 100%;
    max-width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    transition: background-color 0.3s ease, transform 0.3s ease; /* Adds smooth transition */
    font-size: x-small;
  }
  .heatmap-cell:hover {
    transform: scale(.9); /* Slightly increase size to emphasize the hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow for depth */
  }
  .heatmap-second-header-label
  {
    text-align: left;
    user-select: none;
    position: sticky;
    top: 0;
    left:0;
    height: 20px;
    padding-left: 5px;
    z-index: 3;
    font-size: small;
    width: 100px;
    overflow: hidden;
  }
  .darktheme .heatmap-second-header-label {
    background-color: rgb(45, 44, 44);
  }
 
  .lighttheme .heatmap-second-header-label {
    background-color: rgb(238, 238, 238);
  }
  .heatmap-cell-tooltip
  {
    position: fixed;
    top: 50px;
    left: 50px;
    size: medium;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
    display: none;
    width:250px;
    font-size: medium;
    
    
  }
  .darktheme .heatmap-cell-tooltip {
    background-color: rgb(45, 44, 44);
    color: #fff;
    box-shadow: 0 4px 8px #000000, 0px 2px 2px #333;
  }
  .lighttheme .heatmap-cell-tooltip {
    background-color: rgb(238, 238, 238);
    color: #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .heatmap-cell:hover .heatmap-cell-tooltip
  {
    display: block;
  }
  .tooltipguide
  {
    font-style: italic;
  }