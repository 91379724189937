.taskListWrapper {
  display: table;
  margin-top: 2px;;
  /*border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;*/
  /*border-right: #D3D3D3 1px solid;*/
}

.taskListTableRow {
  display: table-row;
  text-overflow: ellipsis;
}
.taskListTableRow:hover {
  background-color: #e5e5e5!important;
  
}
.taskListTableRow:hover .submenudots {
  opacity: 1;
  
}
.submenudots {
  opacity: 0;
  cursor: pointer;
}

.taskListTableRow:nth-of-type(even) {
  background-color: #f5f5f5;
}

.taskListTableRowDark {
  display: table-row;
  text-overflow: ellipsis;
}
.taskListTableRowDark:hover {
  background-color: #8a8886!important;
  
}
.taskListTableRowDark:hover .submenudots {
  opacity: 1;
  
}
.taskListTableRowDark:nth-of-type(even) {
  background-color: #3b3a39;
}

.taskListCell {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.taskListNameWrapper {
  display: flex;
}

.taskListExpander {
  color: rgb(86 86 86);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  user-select: none;
  cursor: pointer;
}
.taskListEmptyExpander {
  font-size: 0.6rem;
  padding-left: 0.5rem;
  user-select: none;
}
.taskListExpanderImage {
  width:10;
  margin-top:3;
  transition: transform .2s ease-in-out;
}
.taskplanheadertext
{
  font-weight: 900;text-decoration: underline;
  margin-left: 0.25rem;
  margin-top: auto;
    margin-bottom: auto;
  
}
.taskprojectheadertext
{
  font-weight: 900;
  margin-left: 0.5rem;
}
.tasktaskheadertext
{
  margin-left: 1rem;
  font-weight: 100;
}
.noassignments
{
  opacity: 0;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.noassignments span
{
  
}
.taskListTableRow:hover .noassignments {
  opacity: 1;
  
}

.assignments
{
 
  cursor: pointer;
}
