.projectWrapper {
  cursor: pointer;
  outline: none;
}

.projectBackground {
  user-select: none;
  opacity: 0.6;
}

.projectTop {
  user-select: none;
  opacity: 0;
}
.projectWrapper rect
{

  transition-property: x, width; 
  transition-duration: .5s; 
  transition-timing-function: ease-out; 
  
}
.projectExpandIcon 
{
  fill: transparent;
  transition-property: fill; 
  transition-duration: .5s; 
  transition-timing-function: ease-out; 
  user-select: none;
}

.projectWrapper:hover > .projectExpandIcon {
  fill: white;
}